/* .container.contact__container {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
} */

.contact__options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media  screen and (max-width: 1024px) {
    .contact__options {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}


/* =============== MEDIA QUERIES (SMALL DEVICES) =================== */
@media  screen and (max-width: 600px) {
    .contact__options {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}